import React, { useRef, useState, useEffect } from "react";
import { userWithTempData } from "../redux/actions/userAuth";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DownloadTableExcel } from "react-export-table-to-excel";
const UserWithTemp = () => {
  const status = useSelector((state) => state);
  const dispatch = useDispatch();
  const { device_id, startDate, endDate } = useParams();
  const tableRef = useRef(null);
  const [oneDayUse1, setOneDayUse] = useState();
  // user per day data
  useEffect(() => {
    dispatch(userWithTempData(device_id, startDate, endDate));
  }, []);
  useEffect(() => {
    if (status.userWithTempData?.successData?.data !== "") {
      if (status.userWithTempData?.successData?.status === 200) {
        setOneDayUse(status?.userWithTempData?.successData?.data);
      }
    }
  }, [status]);
  const parmsName = window.sessionStorage.getItem("eParmsName");
  const parmsDeviceId = window.sessionStorage.getItem("eParmsDeviceId");

  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            Cookstove Usage
            <span>
              <img alt="" src="../../image/leaf.svg"></img> / Cookstove Usage /
              Cookstove Usage Summary
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap">
          <div className="tablewrappadd filter-energy-d">
            <div className="energy-detail-header">
              <h2>{parmsName}</h2>
              <p>{parmsDeviceId}</p>
            </div>
            <div className="filter-data-eng-summary">
              <div className="download-excel">
                <DownloadTableExcel
                  filename={`${parmsName}'s Device a day Details`}
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <button>
                    <FileDownloadIcon style={{ color: "#fff" }} /> Export Excel{" "}
                  </button>
                </DownloadTableExcel>
              </div>
            </div>
          </div>
          <div className="customtabel EnergySummary1">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                ref={tableRef}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Current Temperature</TableCell>
                    <TableCell>Time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {oneDayUse1 !== null
                    ? oneDayUse1?.map((device, i) => {
                        const originalDate = device?.ts;
                        const trimDate = moment(originalDate)
                          .toISOString()
                          .slice(0, -1);
                        const formattedDate =
                          moment(trimDate).format("MMM DD, YYYY");
                        var originalDate1 = moment(originalDate);
                        const trimTime1 = moment(originalDate1)
                          .toISOString()
                          .slice(0, -1);
                        var formattedTime = moment(trimTime1).format("hh:mm A");
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                            key={i}
                          >
                            <TableCell>
                              <div className="tfright">
                                <p className="green">#{device?.id}</p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p className="green">
                                   {device?.device_data_id}
                                </p>
                                {/* <span>REF #{parmsDeviceRef}</span> */}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>{device?.cur_t}</p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>{formattedDate}</p>
                                <span>{formattedTime}</span>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserWithTemp;
