import axios from "axios";
import { auth, base_url } from "../../config";
import { user } from "../constant";
import { toast } from "react-toastify";
import { Password } from "@mui/icons-material";
import { useParams } from "react-router-dom";



// login

export const userLogin = (formData) => (dispatch) => {
  const url = `${base_url}public/login`;
  const data = formData;
  axios
    .post(url, data)
    .then((response) => {
      window.sessionStorage.setItem(
        "userToken",
        `Bearer ${response.data.token}`
      );
      dispatch({
        type: user.USER_LOGIN,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: user.USER_LOGIN_FAILED,
        payload: error,
      });
      toast.error(error.message, {
        position: "bottom-right",
        theme: "colored",
      });
    });
};

// logout

export const logout = (formData) => (dispatch) => {
  const url = `${base_url}admin/user/logout`;
  const data = formData;
  const headers = { Authorization: auth };

  axios
    .post(url, {}, { headers })
    .then((response) => {
      dispatch({
        type: user.LOGOUT,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: user.LOGOUT_FAILED,
        payload: error,
      });
    });
};

// change Password

export const changePassword = (formData) => (dispatch) => {
  const url = `${base_url}admin/user/change-password`;
  const data = formData;
  const headers = { Authorization: auth };

  axios
    .post(url, data, { headers })
    .then((response) => {
      dispatch({
        type: user.CHANGE_PASSWORD,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: user.CHANGE_PASSWORD_FAILED,
        payload: error,
      });
    });
};

// forgot Password

export const forgotpassword = (formData) => (dispatch) => {
  const url = `${base_url}public/forget-password`;
  const data = formData;
  const headers = { Authorization: auth };

  console.log("URL:", url);
  console.log("Data:", data);
  console.log("Headers:", headers);

  axios
    .post(url, data, headers)
    .then((response) => {
      dispatch({
        type: user.FORGOT_PASSWORD,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: user.FORGOT_PASSWORD_FAILED,
        payload: error,
      });
    });
};

// all users

export const allUser = (project) => (dispatch) => {
  const url = `${base_url}admin/user/all`;
  const params = {
    project: project === "" ? null : project,
  
  };
  const headers = { Authorization: auth };
  axios
    .get(url, { headers, params })
    .then((response) => {
      dispatch({
        type: user.ALL_USER,
        payload: response,
      });
      console.log("res===", response);
    })
    .catch((error) => {
      dispatch({
        type: user.ALL_USER_FAILED,
        payload: error,
      });
    });
};
export const filterallUser = (project , block, panchayat, village , district, state) => (dispatch) => {
  const url = `${base_url}admin/user/all`;
  const params = {
    project: project === "" ? null : project,
    block: block === "" ? null : block,
    panchayat: panchayat === "" ? null : panchayat,
    village: village === "" ? null : village,
    district: district === "" ? null : district,
    state: state === "" ? null : state,
  };
  const headers = { Authorization: auth };


  axios
    .get(url, { headers, params })
    .then((response) => {
      dispatch({
        type: user.ALL_USER,
        payload: response,
      });
      console.log("res===", response);
      console.log(headers , "Headers from userAuth")
    })
    .catch((error) => {
      dispatch({
        type: user.ALL_USER_FAILED,
        payload: error,
      });
    });
};

// add new user

export const addNewDevice = (formData) => (dispatch) => {
  const url = `${base_url}admin/device`;
  const data = formData;
  const headers = { Authorization: auth };

  console.log("URL:", url);
  console.log("Data:", data);
  console.log("Headers:", headers);

  axios
    .post(url, data, { headers })
    .then((response) => {
      dispatch({
        type: user.ADD_NEW_DEVICE,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: user.ADD_NEW_DEVICE_FAILED,
        payload: error,
      });
    });
};

// add new user

export const addNewUser = (formData) => (dispatch) => {
  const url = `${base_url}admin/register`;
  const data = formData;
  const headers = { Authorization: auth };

  console.log("URL:", url);
  console.log("Data:", data);
  console.log("Headers:", headers);

  axios
    .post(url, data, { headers })
    .then((response) => {
      dispatch({
        type: user.ADD_NEW_USER,
        payload: response,
      });
      console.log("res--@@---", response);
    })
    .catch((error) => {
      dispatch({
        type: user.ADD_NEW_USER_FAILED,
        payload: error,
      });
      console.log("error user--@@---", error);
    });
};

// import bulk

export const importBulk = (formData) => (dispatch) => {
  const url = `${base_url}admin/bulkUpload`;
  const data = formData;
  const headers = { Authorization: auth };

  console.log("URL:", url);
  console.log("Data:", data);
  console.log("Headers:", headers);

  axios
    .post(url, data, { headers })
    .then((response) => {
      dispatch({
        type: user.IMPORT_BULK,
        payload: response,
      });
      console.log("res--@@---", response);
    })
    .catch((error) => {
      dispatch({
        type: user.IMPORT_BULK_FAILED,
        payload: error,
      });
    });
};

// edit prodile

export const editProfile = (formData, userId) => (dispatch) => {
  console.log("userId -------@@@---", userId);
  const url = `${base_url}public/edit/${userId}`;
  const data = formData;
  const headers = { Authorization: auth };

  axios
    .put(url, data, { headers })
    .then((response) => {
      dispatch({
        type: user.EDIT_PROFILE,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: user.EDIT_PROFILE_FAILED,
        payload: error,
      });
    });
};

// dashboard

export const dashboard = (project, stateName , block , district) => (dispatch) => {
  const url = `${base_url}admin/user/dashboard`;
  const params = {
    project: project === "" ? null : project,
    state: stateName === "" ? null : stateName,
    block: block === "" ? null : block, //changes
    district: district === "" ? null : district, //changes
  };
  const headers = { Authorization: auth };
  axios
    .get(url, { headers, params })
    .then((response) => {
      dispatch({
        type: user.DASHBOARD,
        payload: response,
      });
      console.log("res===", response);
    })
    .catch((error) => {
      dispatch({
        type: user.DASHBOARD_FAILED,
        payload: error,
      });
    });
};

// delete user

export const deleteUser = (userId) => (dispatch) => {
  console.log("568978fgdjgkkdfjdohkllhkjhljhlc----", userId);
  const url = `${base_url}admin/user/users/${userId.id}`;
  const headers = { Authorization: auth };
  axios
    .delete(url, { headers })
    .then((response) => {
      dispatch({
        type: user.DELETE_USER,
        payload: response,
      });
      console.log("res===", response);
    })
    .catch((error) => {
      dispatch({
        type: user.DELETE_USER_FAILED,
        payload: error,
      });
      console.log("res===>>>>>>>", error);
    });
};

// profile user

export const profile = () => (dispatch) => {
  const url = `${base_url}admin/user/user`;
  const headers = { Authorization: auth };
  axios
    .get(url, { headers })
    .then((response) => {
      window.sessionStorage.setItem("profile", `${response.data}`);
      dispatch({
        type: user.PROFILE,
        payload: response,
      });
      console.log("res===", response);
    })
    .catch((error) => {
      dispatch({
        type: user.PROFILE_FAILED,
        payload: error,
      });
    });
};

// energy

export const energy =
  (
    startDateNew,
    endDateNew,
    userId,
    deviceId,
    address,
    project,
    state1,
    block,
    district,
    battery
  ) =>
  (dispatch) => {
    const params = {
      start_date: startDateNew === "" ? null : startDateNew,
      end_date: endDateNew === "" ? null : endDateNew,
      user_id: userId === "" ? null : userId,
      device_id: deviceId === "" ? null : deviceId,
      district: address === "" ? null : address,
      project_name: project === "" ? null : project,
      state: state1 === "" ? null : state1,
      block: block === "" ? null : block,
      district: district === "" ? null : district, //changes
      battery: battery === "" ? null : battery, //changes
    };
    const url = `${base_url}admin/user/user-energy-details`;
    const headers = { Authorization: auth };
    axios
      .get(url, { headers, params })
      .then((response) => {
        dispatch({
          type: user.ENERGY,
          payload: response,
        });
        console.log("ENERGY res========", response.data);
      })
      .catch((error) => {
        dispatch({
          type: user.ENERGY_FAILED,
          payload: error,
        });
      });
  };

// dashboardUserGraph

export const dashboardUserGraph = (project, stateName , block , district) => (dispatch) => {
  const url = `${base_url}admin/user/users-with-months-new`;
  const params = {
    project: project === "" ? null : project,
    state: stateName === "" ? null : stateName,
    block: block === "" ? null : block,
    district: district === "" ? null : district,
  };
  const headers = { Authorization: auth };
  axios
    .get(url, { headers, params })
    .then((response) => {
      dispatch({
        type: user.DASHBOARD_USER_GRAPH,
        payload: response,
      });
      console.log("res===", response);
    })
    .catch((error) => {
      dispatch({
        type: user.DASHBOARD_USER_GRAPH_FAILED,
        payload: error,
      });
    });
};

// dashboardUserGraph

export const dashboardDeviceGraph = (project, stateName , block , district) => (dispatch) => {
  const url = `${base_url}admin/user/users-with-months-device`;
  const params = {
    project: project === "" ? null : project,
    state: stateName === "" ? null : stateName,
    block: block === "" ? null : block, //changes
    district: district === "" ? null : district, //changes
  };
  const headers = { Authorization: auth };
  axios
    .get(url, { headers, params })
    .then((response) => {
      dispatch({
        type: user.DASHBOARD_DEVICE_GRAPH,
        payload: response,
      });
      console.log("res===", response);
    })
    .catch((error) => {
      dispatch({
        type: user.DASHBOARD_DEVICE_GRAPH_FAILED,
        payload: error,
      });
    });
};

// userbyid

export const userById = (userId) => (dispatch) => {
  const url = `${base_url}admin/user/userbyid/${userId.userId}`;
  const headers = { Authorization: auth };
  axios
    .get(url, { headers })
    .then((response) => {
      dispatch({
        type: user.USERBYID,
        payload: response,
      });
      console.log("res===", response);
    })
    .catch((error) => {
      dispatch({
        type: user.USERBYID_FAILED,
        payload: error,
      });
    });
};

// all device

export const allDevice = (dvName, battery, project) => (dispatch) => {
  const url = `${base_url}admin/user/alldevices`;
  const headers = { Authorization: auth };
  const params = {
    device_name: dvName === "" ? null : dvName,
    battery: battery === "" ? null : battery,
    project: project === "" ? null : project,
  };
  axios
    .get(url, { headers, params })
    .then((response) => {
      dispatch({
        type: user.ALL_DEVICE,
        payload: response,
      });
      console.log("res===", response);
    })
    .catch((error) => {
      dispatch({
        type: user.ALL_DEVICE_FAILED,
        payload: error,
      });
    });
};
// Energy Summary

export const Energysummary =
  (userId, startDateNew, endDateNew) => (dispatch) => {
    console.log("userId ----", userId);
    const params = {
      start_date: startDateNew === "" ? null : startDateNew,
      end_date: endDateNew === "" ? null : endDateNew,
    };
    //const url = `${base_url}admin/user/user-energy-details?user_id=${userId.userId}`;
    const url = `${base_url}admin/user/get-filtered-energy-details/${userId.userId}`;
    const headers = { Authorization: auth };
    axios
      .get(url, { headers, params })
      .then((response) => {
        dispatch({
          type: user.ENERGY_SUMMARY,
          payload: response,
        });
        console.log("res===", response);
      })
      .catch((error) => {
        dispatch({
          type: user.ENERGY_SUMMARY_FAILED,
          payload: error,
        });
      });
  };

// delete Device

export const deleteDevice = (device_id) => (dispatch) => {
  console.log("delete device----", device_id);
  const url = `${base_url}admin/user/devices/${device_id.id}`;
  const headers = { Authorization: auth };
  axios
    .delete(url, { headers })
    .then((response) => {
      dispatch({
        type: user.DELETE_DEVICE,
        payload: response,
      });
      console.log("res===", response);
    })
    .catch((error) => {
      dispatch({
        type: user.DELETE_DEVICE_FAILED,
        payload: error,
      });
      console.log("res===>>>>>>>", error);
    });
};

// edit device

export const editDevice = (device_id, deviceData) => (dispatch) => {
  const url = `${base_url}public/devices/${device_id}`;
  const headers = { Authorization: auth };

  axios
    .put(url, deviceData, { headers })
    .then((response) => {
      dispatch({
        type: user.EDIT_DEVICE,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: user.EDIT_DEVICE_FAILED,
        payload: error,
      });
    });
};

// import bulk user

export const importBulkUser = (formData) => (dispatch) => {
  const url = `${base_url}admin/bulkUploaduser`;
  const data = formData;
  const headers = { Authorization: auth };

  console.log("URL:", url);
  console.log("Data:", data);
  console.log("Headers:", headers);

  axios
    .post(url, data, { headers })
    .then((response) => {
      dispatch({
        type: user.IMPORT_BULK_USER,
        payload: response,
      });
      console.log("res--@@---", response);
    })
    .catch((error) => {
      dispatch({
        type: user.IMPORT_BULK_USER_FAILED,
        payload: error,
      });
    });
};

export const assignDevice = (formData) => (dispatch) => {
  const url = `${base_url}admin/user/assign-device`;
  const data = formData;
  const headers = { Authorization: auth };

  console.log("URL:", url);
  console.log("Data:", data);
  console.log("Headers:", headers);

  axios
    .post(url, data, { headers })
    .then((response) => {
      dispatch({
        type: user.ASSIGN_DEVICE,
        payload: response,
      });
      console.log("res--@@---", response);
    })
    .catch((error) => {
      dispatch({
        type: user.ASSIGN_DEVICE_FAILED,
        payload: error,
      });
    });
};

export const filterUserData = (formData) => (dispatch) => {
  const data = formData;
  const url = `${base_url}public/energy-details/${data}`;
  const headers = { Authorization: auth };

  console.log("URL:", url);
  console.log("Data:", data);
  console.log("Headers:", headers);

  axios
    .get(url, { headers })
    .then((response) => {
      dispatch({
        type: user.FILTER_USER_DATA,
        payload: response,
      });
      console.log("res--@@---", response);
    })
    .catch((error) => {
      dispatch({
        type: user.FILTER_USER_DATA_FAILED,
        payload: error,
      });
    });
};

export const filterDeviceData = (formData) => (dispatch) => {
  const data = formData;
  const url = `${base_url}admin/user/filtered-energy-details-on-device-id/${data}`;
  const headers = { Authorization: auth };

  console.log("URL:", url);
  console.log("Data:", data);
  console.log("Headers:", headers);

  axios
    .get(url, { headers })
    .then((response) => {
      dispatch({
        type: user.FILTER_DEVICE_DATA,
        payload: response,
      });
      console.log("res--@@---", response);
    })
    .catch((error) => {
      dispatch({
        type: user.FILTER_DEVICE_DATA_FAILED,
        payload: error,
      });
    });
};

export const userAndDevices = () => (dispatch) => {
  const url = `${base_url}admin/user/user-energy-details`;
  const headers = { Authorization: auth };

  console.log("URL:", url);
  console.log("Headers:", headers);

  axios
    .get(url, { headers })
    .then((response) => {
      dispatch({
        type: user.USER_AND_DEVICES,
        payload: response,
      });
      console.log("res--@@---", response);
    })
    .catch((error) => {
      dispatch({
        type: user.USER_AND_DEVICES_FAILED,
        payload: error,
      });
    });
};

export const mapUser = () => (dispatch) => {
  const url = `${base_url}admin/user/useraverage`;
  const headers = { Authorization: auth };

  console.log("URL:", url);
  console.log("Headers:", headers);

  axios
    .get(url, { headers })
    .then((response) => {
      dispatch({
        type: user.MAP_USER,
        payload: response,
      });
      console.log("res--@@---", response);
    })
    .catch((error) => {
      dispatch({
        type: user.MAP_USER_FAILED,
        payload: error,
      });
    });
};

export const userPerDay = (deviceId, startDate) => (dispatch) => {
  const url = `${base_url}admin/user/userswithanotherdevicedata?device_id=${deviceId}&start_date=${startDate}`;
  const headers = { Authorization: auth };

  axios
    .get(url, { headers })
    .then((response) => {
      dispatch({
        type: user.USER_PER_DAY,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: user.USER_PER_DAY_FAILED,
        payload: error,
      });
    });
};

export const addProject = (name) => (dispatch) => {
  const data = name;
  const url = `${base_url}admin/user/projects`;
  const headers = { Authorization: auth };

  axios
    .post(url, data, { headers })
    .then((response) => {
      dispatch({
        type: user.ADD_PROJECT,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: user.ADD_PROJECT_FAILED,
        payload: error,
      });
    });
};
export const projectList = () => (dispatch) => {
  const url = `${base_url}admin/user/projectsget`;
  const headers = { Authorization: auth };

  axios
    .get(url, { headers })
    .then((response) => {
      dispatch({
        type: user.PROJECT_LIST,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: user.PROJECT_LIST_FAILED,
        payload: error,
      });
    });
};
// project edit
export const projectEdit = (project) => (dispatch) => {
  const url = `${base_url}admin/user/projectsedit`;
  const headers = { Authorization: auth };
  const data = project;
  axios
    .put(url, data, { headers })
    .then((response) => {
      dispatch({
        type: user.PROJECT_EDIT,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: user.PROJECT_EDIT_FAILED,
        payload: error,
      });
    });
};

// PAI CHART devices
export const paiChartDevice =
  (sDate, eDate, project, stateName , block , district) => (dispatch) => {
    const url = `${base_url}admin/user/getcategorizeduser`;
    const headers = { Authorization: auth };
    const params = {
      //date: date === "" ? null : date,
      startdate: sDate === "" ? null : sDate,
      enddate: eDate === "" ? null : eDate,
      project: project === "" ? null : project,
      state: stateName === "" ? null : stateName,
      block: block === "" ? null : block, //changes
      district: district === "" ? null : district, //changes
    };
    axios
      .get(url, { headers, params })
      .then((response) => {
        dispatch({
          type: user.PAI_CHART_DEVICE,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: user.PAI_CHART_DEVICE_FAILED,
          payload: error,
        });
      });
  };

// Average Device Usage
export const averageDeviceUsage =
  (sDate, eDate, project, stateName, block, district , deviceId) => (dispatch) => {
    const url = `${base_url}admin/user/get-average-usage-by-date`;
    const headers = { Authorization: auth };
    const params = {
      startdate: sDate === "" ? null : sDate,
      enddate: eDate === "" ? null : eDate,
      project: project === "" ? null : project,
      state: stateName === "" ? null : stateName,
      block: block === "" ? null : block, //changes
      district: district === "" ? null : block, //changes
      device: deviceId === "" ? null : deviceId, 
    };
    axios
      .get(url, { headers, params })
      .then((response) => {
        dispatch({
          type: user.AVERAGE_DEVICE_USAGE,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: user.AVERAGE_DEVICE_USAGE_FAILED,
          payload: error,
        });
      });
  };
// user with temp data devices
export const userWithTempData = (devId, startTime, endTime) => (dispatch) => {
  const url = `${base_url}admin/user/userwithtemperaturedata?device_id=${devId}&start_time=${startTime}&end_time=${endTime}`;
  const headers = { Authorization: auth };
  axios
    .get(url, { headers })
    .then((response) => {
      dispatch({
        type: user.USER_WITH_TEMPERATURE,
        payload: response,
      });
    })
    .catch((error) => {
      dispatch({
        type: user.USER_WITH_TEMPERATURE_FAILED,
        payload: error,
      });
    });
};
