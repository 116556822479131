import React, { useState } from "react";
import "../css/filter.css";

const SearchableDropdown = ({ name, data, filterProject, allProject }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  console.log(data, "The data we are getting is");

  // Handle input change
  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
    setIsDropdownOpen(e.target.value ? true : false);
  };

  // Handle selecting an item from the dropdown
  const handleSelect = (data, e) => {
    console.log("The data we are getting is", data);
    console.log("e.target.value is", e.target.value); // This might not be necessary
    
    filterProject(data);
    setSearchTerm(data);
    setIsDropdownOpen(false);
  };

  // Filter data based on the search term
  const filteredData = data?.filter((item) =>
    item?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Close dropdown if clicked outside
  const handleClickOutside = (e) => {
    if (e.target.closest(".dropdown") === null) {
      setIsDropdownOpen(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleEverything = async (e) => {
    try {
      allProject(e);
      setSearchTerm("");
      setIsDropdownOpen(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="dropdown">
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder={name}
        className="search-input"
        onFocus={() => setIsDropdownOpen(true)}
      />
      {isDropdownOpen && (
        <ul className="dropdown-list">
          <div className="dropdown-item" onClick={(e) => handleEverything(e)}>
            {name}
          </div>
          {filteredData?.length > 0 ? (
            filteredData?.map((data, index) => (
              <li
                key={index}
                className="dropdown-item"
                onClick={(e) => handleSelect(data,e)} // Pass `data` directly
              >
                {data}
              </li>
            ))
          ) : (
            <li className="dropdown-item">No results</li>
          )}
        </ul>
      )}
    </div>
  );
};

export default SearchableDropdown;
