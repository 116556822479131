// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Global reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  /* body {
    font-family: Arial, sans-serif;
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  } */
  
  .dropdown {
    position: relative;
    width: 100px;
  }
  
  .search-input {
    width: 100%;
    padding: 4px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    background-color: #fff;
  }
  
  .dropdown-list {
    position: absolute;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-top: 4px;
    z-index: 10;
  }
  
  .dropdown-item {
    padding: 8px;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    background-color: #f1f1f1;
  }
  
  .dropdown-item:active {
    background-color: #ddd;
  }
  `, "",{"version":3,"sources":["webpack://./src/css/filter.css"],"names":[],"mappings":"AAAA,iBAAiB;AACjB;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;EACxB;;EAEA;;;;;;;KAOG;;EAEH;IACE,kBAAkB;IAClB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,sBAAsB;EACxB;;EAEA;IACE,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,gBAAgB;IAChB,sBAAsB;IACtB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,WAAW;EACb;;EAEA;IACE,YAAY;IACZ,eAAe;EACjB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;EACxB","sourcesContent":["/* Global reset */\n* {\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n  }\n  \n  /* body {\n    font-family: Arial, sans-serif;\n    background-color: #f7f7f7;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n  } */\n  \n  .dropdown {\n    position: relative;\n    width: 100px;\n  }\n  \n  .search-input {\n    width: 100%;\n    padding: 4px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    font-size: 14px;\n    background-color: #fff;\n  }\n  \n  .dropdown-list {\n    position: absolute;\n    width: 100%;\n    max-height: 150px;\n    overflow-y: auto;\n    background-color: #fff;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n    margin-top: 4px;\n    z-index: 10;\n  }\n  \n  .dropdown-item {\n    padding: 8px;\n    cursor: pointer;\n  }\n  \n  .dropdown-item:hover {\n    background-color: #f1f1f1;\n  }\n  \n  .dropdown-item:active {\n    background-color: #ddd;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
