import React from "react";
import { userById } from "../redux/actions/userAuth";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";

const Profile = () => {
  const status = useSelector((state) => state);
  const dispatch = useDispatch();
  const [userprofile, setUserProfile] = useState([]);

  console.log("---222000----", userprofile);
  const { userId } = useParams();
  console.log("new profile id", userId);

  let param = window.location.href;
  const queryString = param.split("?")[1];
  if (queryString) {
    param = queryString.split("=")[1];
  } else {
    param = "";
  }
  useEffect(() => {
    const apiParams = { userId };
    dispatch(userById(apiParams, userId));
  }, [userId]);

  useEffect(() => {
    if (status.userById.data !== "") {
      if (status.userById.data.status === 200) {
        setUserProfile(status.userById.data.data);
        console.log("@@@----", setUserProfile);
      }
    }
  }, [status]);

  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            All Users
            <span>
              <img src="../../image/users.svg"></img> / All Users /{" "}
              {userprofile.name}
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap inputwrap">
          <div className="tablewrappadd">
            <div className="bothwrap">
              <div className="adduserhadd">
                <h2>{userprofile.name}</h2>
                <p>User Profile</p>
              </div>
              <div className="mainbtn">
                <Link to={`/dashboard/ProfileSettings/${userprofile.userId}`}>
                  Edit Profile
                </Link>
              </div>
            </div>

            <div className="adduserwrap">
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/profile.svg"></img>
                  </span>
                  <p>{userprofile?.name ? userprofile?.name: "Name"}</p>
                </div>
              </div>
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/phone.svg"></img>
                  </span>
                  <p>{userprofile?.phone ? userprofile?.phone : "Phone"}</p>
                </div>
              </div>


              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <p>{userprofile?.block ? userprofile?.block : "Block"}</p>
                </div>
              </div>
            </div>

            <div className="adduserwrap">
              {/* <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/lock.svg"></img>
                  </span>
                  <p>{userprofile.password}</p>
                </div>
              </div> */}
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <p>{userprofile?.panchayat ? userprofile?.panchayat : "Panchayat"}</p>
                </div>
              </div>

              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <p>{userprofile?.district ? userprofile?.district : "District"}</p>
                </div>
              </div>
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <p>{userprofile?.state ? userprofile?.state : "State"}</p>
                </div>
              </div>
            </div>
            <div className="adduserwrap">
              {/* <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <p>{userprofile.pincode}</p>
                </div>
              </div> */}
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/flag.svg"></img>
                  </span>
                  <p>{userprofile?.country ? userprofile?.country : "Country"}</p>
                </div>
              </div>
              <div className="selectfield">
                <div className="customfield">
                  <div className="profilewrap">
                    <span className="pageimage">
                      <img alt="img" src="../../image/users.svg"></img>
                    </span>
                    <p>{userprofile?.role ? userprofile?.role : "Role"}</p>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="proof">Proof of identity</h4>
            <div className="adduserwrap">
              <div className="customfield">
                <div className="profilewrap">
                  <span className="pageimage">
                    <img alt="img" src="../../image/profile.svg"></img>
                  </span>
                  <p>{userprofile.aadharcard != null ? "Aadhar Number" : userprofile?.ration_card?.String != "" ? "Ration Number" : "Voter Id"}</p>
                </div>
              </div>

                
                 <div className="customfield">
                 <div className="profilewrap">
                  
              {/* {
                (userprofile.aadharcard || userprofile?.ration_card?.String) ? */}

                {/* <> */}
                   <span className="pageimage">
                     <img alt="img" src="../../image/profile.svg"></img>
                   </span>
                   <p>{userprofile?.ration_card?.String != "" ? userprofile?.ration_card?.String : userprofile?.aadharcard != null ? userprofile?.aadharcard : userprofile?.voters_id}</p>
                {/* </> */}
                {/* :
                <></>
              } */}
                 </div>
               </div>

              
             
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
