import React, { useRef } from "react";
import { userPerDay } from "../redux/actions/userAuth";
import Table from "@mui/material/Table";
import { Link } from "react-router-dom";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import moment from "moment";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DownloadTableExcel } from "react-export-table-to-excel";
const UsePerDay = () => {
  const status = useSelector((state) => state);
  const dispatch = useDispatch();
  const { device_id, startDate } = useParams();
  const tableRef = useRef(null);
  const [oneDayUse1, setOneDayUse] = useState();
  // user per day data
  useEffect(() => {
    dispatch(userPerDay(device_id, startDate));
  }, []);
  useEffect(() => {
    if (status.userPerDay?.successData?.data !== "") {
      if (status.userPerDay?.successData?.status === 200) {
        setOneDayUse(status?.userPerDay?.successData?.data);
      }
    }
  }, [status]);
  const parmsName = window.sessionStorage.getItem("eParmsName");
  const parmsDeviceId = window.sessionStorage.getItem("eParmsDeviceId");
  const parmsDeviceRef = window.sessionStorage.getItem("eParmsDeviceRef");

  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            Cookstove Usage
            <span>
              <img alt="" src="../../image/leaf.svg"></img> / Cookstove Usage /
              Cookstove Usage Summary
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap">
          <div className="tablewrappadd filter-energy-d">
            <div className="energy-detail-header">
              <h2>{parmsName}</h2>
              <p> {parmsDeviceId}</p>
            </div>
            <div className="filter-data-eng-summary">
              <div className="download-excel">
                <DownloadTableExcel
                  filename={`${parmsName}'s Device a day Details`}
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <button>
                    <FileDownloadIcon style={{ color: "#fff" }} /> Export Excel{" "}
                  </button>
                </DownloadTableExcel>
              </div>
            </div>
          </div>
          <div className="customtabel EnergySummary1">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                ref={tableRef}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Device ID</TableCell>
                    <TableCell>Start Time</TableCell>
                    <TableCell>End Time</TableCell>
                    <TableCell>Time Difference</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {oneDayUse1 !== null
                    ? oneDayUse1?.map((device, i) => {
                        const originalDate = device?.start_time;
                        const trimDate = moment(originalDate)
                          .toISOString()
                          .slice(0, -1);
                        const formattedDate =
                          moment(trimDate).format("MMM DD, YYYY");
                        var originalDate1 = moment(originalDate);
                        const trimTime1 = moment(originalDate1)
                          .toISOString()
                          .slice(0, -1);
                        var formattedTime = moment(trimTime1).format("hh:mm A");

                        const originalDate2 = device?.end_time;
                        const trimDate2 = moment(originalDate2)
                          .toISOString()
                          .slice(0, -1);
                        const formattedDate2 =
                          moment(trimDate2).format("MMM DD, YYYY");
                        var originalDate3 = moment(originalDate2);
                        const trimTime2 = moment(originalDate3)
                          .toISOString()
                          .slice(0, -1);
                        var formattedTime2 =
                          moment(trimTime2).format("hh:mm A");

                        const totalSeconds = device?.time_difference_hours;
                        const duration = moment.duration(
                          totalSeconds,
                          "seconds"
                        );
                        const hours = Math.floor(duration.asHours());
                        const minutes = duration.minutes();
                        const seconds = duration.seconds();
                        return (
                          <TableRow
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                            key={i}
                          >
                            <TableCell>
                              <div className="tfright tfright2">
                                <Link
                                  to={`/dashboard/user-with-temperature/${device?.device_id}/${device?.start_time}/${device?.end_time}`}
                                >
                                  <p className="green">{device?.name}</p>
                                </Link>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p className="green">{device?.device_id}</p>
                                {/* <span>REF #{device?.device_ref_num}</span> */}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>{formattedDate}</p>
                                <span>{formattedTime}</span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>{formattedDate2}</p>
                                <span>{formattedTime2}</span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>
                                  {`${hours !== 0 ? hours + " hr," : ""} ${
                                    minutes !== 0 ? minutes + " min," : ""
                                  } ${seconds !== 0 ? seconds + " sec" : ""}`}
                                </p>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
};
export default UsePerDay;
