import React, { useRef } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, useNavigate } from "react-router-dom";
import TextField from "@mui/material/TextField";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { DownloadTableExcel } from "react-export-table-to-excel";
import {
  allDevice,
  deleteDevice,
  allUser,
  projectList,
  assignDevice,
} from "../redux/actions/userAuth";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { toast, ToastContainer } from "react-toastify";
const battery = [
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
  {
    value: 60,
    label: "60",
  },
  {
    value: 70,
    label: "70",
  },
  {
    value: 80,
    label: "80",
  },
  {
    value: 90,
    label: "90",
  },
  {
    value: 100,
    label: "100",
  },
];
const AllDevices = () => {
  const status = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [allDevicedata, setAllDevicedata] = useState([]);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const [alluser, setAllUser] = useState([]);
  const [selectProject, setSelectProject] = useState(false);
  const [projectLst, setProjectLst] = useState([]);
  const [checked, setchecked] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState();
  const [filteredProject, setFilteredProject] = useState();
  const [lessThan30 , setLessThan30] = useState([]);
  const [searchData, setSearchData] = useState({
    dv_id: "",
    dv_name: "",
  });
  const [userData, setUserData] = useState({
    device_id: "",
    device_name: "",
    device_ref_num: "",
    name: "",
    // email: "",
    phone: "",
    assignedDateTime: "",
    project_id: "",
    projectName: "",
    battery: "",
  });

  // all user api

  useEffect(() => {
    const apiData = {};
    dispatch(allUser(apiData));
    dispatch(
      allDevice(searchData.dv_name, searchData.battery, searchData.projectName)
    );
  }, []);

  useEffect(() => {
    if (status.allUser.data !== "") {
      if (status.allUser.data.status === 200) {
        const filteredAllUsers = status.allUser.data.data?.filter(
          (item) => !deletedUsers.includes(item.userId)
        );
        setAllUser(filteredAllUsers);
        setFilteredUsers(filteredAllUsers);
      }
    }
  }, [status, deletedUsers]);
  const filterProject = (e) => {
    setUserData({
      ...userData,
      projectName: e.target.value,
    });
    dispatch(allDevice(searchData.dv_name, userData.battery, e.target.value));
  };
  const handleSearchChange1 = (e) => {
    setAllDevicedata(null);
    setSearchData({
      ...searchData,
      dv_name: e.target.value,
    });
    dispatch(
      allDevice(e.target.value, userData.battery, userData?.projectName)
    );
  };
  const clearDataSearch1 = () => {
    setSearchData({
      ...searchData,
      dv_name: "",
    });
    dispatch(allDevice("", userData.battery, userData?.projectName));
  };
  const batteryChange = (e) => {
    setUserData({
      ...userData,
      battery: e.target.value,
    });
    dispatch(
      allDevice(searchData.dv_name, e.target.value, userData?.projectName)
    );
  };
  useEffect(() => {
    if (status.allDevice?.data !== "") {
      if (status.allDevice?.data.status === 200) {
        const allDeviceData1 = status.allDevice.data.data;
        const filteredAllUsers =
          allDeviceData1 !== null &&
          allDeviceData1?.filter(
            (item) => !deletedUsers.includes(item.DeviceID)
          );
        setAllDevicedata(filteredAllUsers ? filteredAllUsers : null);
      }
      if (status.allDevice?.data?.response != null) {
        if (status.allDevice?.data?.response?.status === 401) {
          toast.error(status.allDevice?.data?.response?.data?.error, {
            position: "bottom-right",
            theme: "colored",
          });
          window.sessionStorage.clear();
          let path = `/`;
          navigate(path);
          window.location.reload();
        }
      }
    }
  }, [status, deletedUsers]);
  // all user api
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [isPopupOpen2, setPopupOpen2] = useState(0);
  const openPopup = (device) => {
    setPopupOpen(true);
    setUserData({
      ...userData,
      device_id: device.DeviceID,
      device_name: device.DeviceName.String,
      device_ref_num: device.DeviceRefNum.String,
    });
  };

  const openPopup2 = (device) => {
    setPopupOpen2(device.DeviceID);
  };
  const closePopup = () => {
    setPopupOpen(false);
    setSelectProject(false);
    setchecked(false);
    setFilteredUsers(alluser);
    setFilteredProject(projectLst);
    setUserData({
      ...userData,
      device_id: "",
      device_name: "",
      device_ref_num: "",
      name: "",
      // email: "",
      phone: "",
      assignedDateTime: "",
      project_id: "",
    });
  };
  useEffect(() => {
    document.body.classList.toggle("popup-open", isPopupOpen);
  }, [isPopupOpen]);
  useEffect(() => {
    document.body.classList.toggle("popup-open", isPopupOpen2);
  }, [isPopupOpen2]);

  const deviceData = (item) => {
    setUserData({
      ...userData,
      name: item?.name,
      email: item?.email,
      phone: item?.phone,
    });
    setchecked(true);
  };

  const handleDeleteUser = (DeviceID) => {
    if (DeviceID) {
      const data = { id: DeviceID };
      dispatch(deleteDevice(data));
      setAllDevicedata((prevUsers) =>
        prevUsers?.filter((user) => user.DeviceID !== DeviceID)
      );
      setDeletedUsers((prevDeletedUsers) => [...prevDeletedUsers, DeviceID]);
      toast.success("device deleted successfully! ", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setPopupOpen2(0);
    } else {
      console.error("Invalid user ID:", DeviceID);
    }
  };

  const selectUserName = () => {
    if (checked) {
      setSelectProject(true);
    } else {
      toast.error("Please Select User", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    dispatch(projectList());
  }, []);
  useEffect(() => {
    if (status.projectList?.successData?.data !== "") {
      if (status.projectList?.successData?.status === 200) {
        setProjectLst(status?.projectList?.successData?.data);
        setFilteredProject(status?.projectList?.successData?.data);
      }
    }
  }, [status]);
  const selectedProject = (item) => {
    setUserData({
      ...userData,
      project_id: item?.projectId,
    });
    setchecked(false);
  };
  const assignProject = () => {
    if (!checked) {
      const currDate = new Date();
      const foDate = moment(currDate).toISOString().slice(0, -5) + "Z";
      setUserData({
        ...userData,
        assignedDateTime: foDate,
      });
      const apiData = {
        device_id: userData?.device_id,
        device_name: userData?.device_name,
        device_ref_num: userData?.device_ref_num,
        name: userData?.name,
        // email: userData?.email,
        phone: userData?.phone,
        assignedDateTime: foDate,
        project_id: userData?.project_id,
      };
      dispatch(assignDevice(apiData));
    } else {
      toast.error("Please Select Project", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };
  useEffect(() => {
    if (status.assignDevice?.successData !== "") {
      if (status.assignDevice?.successData?.status === 200) {
        setPopupOpen(false);
        toast.success("device assigned successfully! ", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      }
    }
  }, [status]);
  const unassignUserLength = alluser?.filter(
    (item) => item["deviceStatus"] === "unassigned"
  );
  const unassignUserLength1 = unassignUserLength?.length;
  const handleFilter = (event) => {
    const value = event.target.value.toLowerCase();
    const filtered = alluser?.filter((user) =>
      user?.name.toLowerCase().includes(value)
    );
    setFilteredUsers(filtered);
  };
  const handleFilter1 = (event) => {
    const value = event.target.value.toLowerCase();
    const filtered = projectLst?.filter((user) =>
      user?.projectName.toLowerCase().includes(value)
    );
    setFilteredProject(filtered);
  };

  useEffect(() => {

    allDevicedata?.map((item , index) => {

        if(item.battery.String < 30 && item.battery.String !== ""){

          lessThan30.push(item);

        }

    })

  })

  useEffect(() => {

    console.log("Devices With Less Than 30 Percent" , lessThan30);

    lessThan30.length > 0 && toast.error(`Devices With Less Than 30 Percent are ${lessThan30.length}`)

  }, [allDevicedata])

  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            All Devices
            <span>
              <img alt="" src="../image/dish.svg"></img> / All Devices
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap inputwrap">
          <div className="tablewrappadd filter-energy-d">
            <div className="energy-detail-header">
              <h2>All Devices</h2>
              <p>List of added Devices</p>
            </div>
            <div className="filter-data-eng-summary">
              <div className="download-excel">
                <DownloadTableExcel
                  filename={`All devices Details`}
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <button>
                    <FileDownloadIcon style={{ color: "#fff" }} /> Export Excel{" "}
                  </button>
                </DownloadTableExcel>
              </div>
            </div>
          </div>
          <div className="userFilter">
            <div className="adduserwrap">
              <div className="selectfield">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/project.svg"></img>
                  </span>
                  <Box component="form" noValidate autoComplete="off">
                    <div>
                      <TextField
                        id="filled-select-currency"
                        select
                        label="Select Project Name"
                        variant="filled"
                        onChange={(e) => filterProject(e)}
                      >
                        <MenuItem value={""}>Select Project Name</MenuItem>
                        {projectLst?.map((option) => (
                          <MenuItem
                            key={option?.projectId}
                            value={option?.projectName}
                          >
                            {option?.projectName}
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Box>
                  <img
                    alt="img"
                    src="../image/arrowb.svg"
                    className="arrowb"
                  ></img>
                </div>
              </div>
              <div className="selectfield">
                <div className="customfield search">
                  <span className="pageimage">
                    <img alt="img" src="../image/search.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Search Device Name"
                    maxRows={4}
                    variant="filled"
                    autoComplete="off"
                    onChange={(e) => handleSearchChange1(e)}
                    value={searchData?.dv_name}
                  />
                  {searchData.dv_name !== "" ? (
                    <i className="pageimage-close" onClick={clearDataSearch1}>
                      <CloseIcon />
                    </i>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="selectfield battrey-b-0">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../image/battery.svg"></img>
                  </span>
                  <Box component="form" noValidate autoComplete="off">
                    <div>
                      <TextField
                        id="filled-select-currency"
                        select
                        label="Select Battery (%)"
                        variant="filled"
                        onChange={(e) => batteryChange(e)}
                      >
                        <MenuItem value={""}>Select Battery (%)</MenuItem>
                        {battery?.map((option, i) => (
                          <MenuItem key={i} value={option?.value}>
                            {option?.label}%
                          </MenuItem>
                        ))}
                      </TextField>
                    </div>
                  </Box>
                  <img
                    alt="img"
                    src="../image/arrowb.svg"
                    className="arrowb"
                  ></img>
                </div>
              </div>
            </div>
          </div>
          <div className="customtabel">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 650 }}
                aria-label="simple table"
                ref={tableRef}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Stove ID</TableCell>
                    <TableCell>Device Name/ID</TableCell>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Status</TableCell>
                    <TableCell>Device added on</TableCell>
                    <TableCell>Assign Device</TableCell>
                    <TableCell>Assigned Date</TableCell>
                    <TableCell>Last Reported Date</TableCell>
                    <TableCell>Battery (%)</TableCell>
                    <TableCell>Device Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {allDevicedata !== null
                    ? allDevicedata?.map((device) => {
                        const reportDate = device?.last_reported_time?.Time;
                        const trimDate = moment(reportDate)
                          .toISOString()
                          .slice(0, -1);
                        const formattedDate =
                          moment(trimDate).format("MMM DD, YYYY");
                        const formattedTime =
                          moment(trimDate).format("hh:mm A");
                        return (
                          <TableRow
                            key={device?.DeviceID}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              <div className="tfright">
                                <p className="green">
                                  {device?.stoveID?.String}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <div className="tfright">
                                <p className="green">
                                  {device?.DeviceName?.String}
                                </p>
                                <span>{device?.DeviceID}</span>
                              </div>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <div className="tfright">
                                <p className="green">
                                  {device?.project.String}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p className="status">
                                  {device["BtAssigned"] === 0 ? (
                                    <p className="redColor">Not Assigned</p>
                                  ) : (
                                    ""
                                  )}
                                  {device["BtAssigned"] === 1 ? (
                                    <p className="greenColor">Active</p>
                                  ) : (
                                    ""
                                  )}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>{device.added_date?.String}</p>
                                <span>{device.AddedTime?.String}</span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfirst">
                                <div className="tfleft tfleftnew">
                                  <span>
                                    {device["BtAssigned"] === 0 ? (
                                      <button onClick={() => openPopup(device)}>
                                        <img alt="" src="../image/add.svg" />
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                    {device["BtAssigned"] === 1 ? (
                                      <button onClick={() => openPopup(device)}>
                                        <img alt="" src="../image/edit.svg" />
                                      </button>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </div>
                                <div className="tfright">
                                  <p className="green">
                                    <Link to="/dashboard/EnergySummary">
                                      {device.AssigneeName.String}
                                    </Link>
                                  </p>
                                  <span>{device?.AssigneePhone?.String}</span>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>{device.assigned_date?.String}</p>
                                <span>{device.AssignedTime?.String}</span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>
                                  {device?.last_reported_time?.Valid
                                    ? formattedDate
                                    : ""}
                                </p>
                                <span>
                                  {device?.last_reported_time?.Valid
                                    ? formattedTime
                                    : ""}
                                </span>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>{device?.battery?.String}</p>
                              </div>
                            </TableCell>
                            <TableCell>
                              <div className="tfright">
                                <p>
                                  <Link
                                    to={`/dashboard/EditDevice/${device.DeviceID}`}
                                  >
                                    <img alt="" src="../image/edit2.svg" />
                                  </Link>{" "}
                                  <b onClick={() => openPopup2(device)}>
                                    <img alt="" src="../image/delete.svg" />
                                  </b>
                                </p>
                              </div>
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
            {allDevicedata === null ? (
              <div className="no-data-found">
                <img alt="img" src="/image/noDataFound.svg"></img>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      {isPopupOpen && (
        <div className="popup-overlay">
          <div className="popbg">
            <div className="popup-content" id="style-1">
              <div className="pop-top-heading">
                <h6>Select {selectProject ? "Project" : "User"}</h6>
                {selectProject ? (
                  <input
                    type="text"
                    onChange={handleFilter1}
                    className="user-filter"
                    placeholder="Filter project name"
                  />
                ) : (
                  <input
                    type="text"
                    onChange={handleFilter}
                    className="user-filter"
                    placeholder="Filter user name"
                  />
                )}
              </div>
              <div className="popup-inner-body">
                {selectProject === true ? (
                  filteredProject?.map((projectList, i) => (
                    <div className="poptext project-class" key={i}>
                      <label htmlFor={`inputpro${i}`}>
                        <p onClick={() => selectedProject(projectList)}>
                          <b>{projectList?.projectName}</b>
                          <span># {projectList?.projectId}</span>
                        </p>
                      </label>
                      <input
                        name="project"
                        type="radio"
                        value={projectList?.projectId}
                        id={`inputpro${i}`}
                      />
                      <span className="radio-btn-check"></span>
                    </div>
                  ))
                ) : unassignUserLength1 !== 0 ? (
                  filteredUsers?.map((alluser, i) => (
                    <div className="poptext userlist-class">
                      {alluser["deviceStatus"] === "unassigned" ? (
                        <>
                          <label htmlFor={`input${i}`}>
                            <p onClick={() => deviceData(alluser)}>
                              <b>{alluser?.name}</b>
                              {/* <span>{alluser?.email}</span> */}
                            </p>
                          </label>
                          <input
                            name="user"
                            type="radio"
                            value={alluser?.userId}
                            id={`input${i}`}
                          />
                          <span className="radio-btn-check"></span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  ))
                ) : (
                  <div className="no-device-avl">No User Available</div>
                )}
              </div>
              {selectProject ? (
                <button
                  className="popupCloseBtn greenbtn"
                  onClick={assignProject}
                >
                  Assign
                </button>
              ) : (
                <button
                  className="popupCloseBtn greenbtn"
                  onClick={selectUserName}
                >
                  ok
                </button>
              )}
              <button className="popupCloseBtn" onClick={closePopup}>
                Close
              </button>
            </div>
          </div>
        </div>
      )}
      {isPopupOpen2 !== 0 && (
        <div className="popup-overlay">
          <div className="popbg">
            <div className="popup-content" id="style-1">
              <div className="pop-top-heading">
                <h6>Delete Device</h6>
              </div>
              <div className="popup-inner-body">
                <p>Are you sure you want to delete this Device?</p>
                <p>Device Id - {isPopupOpen2}</p>
              </div>
              <button
                className="popupCloseBtn red-btn"
                onClick={() => handleDeleteUser(isPopupOpen2)}
              >
                Delete
              </button>
              <button
                className="popupCloseBtn"
                onClick={() => setPopupOpen2(0)}
              >
                cancel
              </button>
            </div>
          </div>
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default AllDevices;
