import React from "react";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editProfile, userById } from "../redux/actions/userAuth";
import { useNavigate, Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
const currencies = [
  {
    value: "user",
    label: "User",
  },
  {
    value: "admin",
    label: "Admin",
  },
];

const ProfileSettings = () => {
  const status = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userId = parseInt(useParams().userId);
  console.log("@@@dispatch", status);

  const [userProfileData, setUserProfileData] = useState("");

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");
  const [profileImage, setProfileImage] = useState("");
  const [pincode, setpPinCode] = useState("");
  const [city, setCity] = useState("");
  const [country, setCountry] = useState("");
  const [role, setRole] = useState("");
  const [aadharcard, setAadharCard] = useState("");
  const [rationCard, setRationCard] = useState("");
  const [block , setBlock] = useState("");
  const [panchayat , setPanchayat] = useState("");
  const [district , setdistrict] = useState("");
  const [state , setstate] = useState("");
  // profile
  useEffect(() => {
    const apiParams = { userId };
    dispatch(userById(apiParams, userId));
  }, [userId]);

  useEffect(() => {
    if (status.userById.data !== "") {
      if (status.userById.data.status === 200) {
        setUserProfileData(status.userById.data.data);
        setName(status.userById?.data?.data?.name);
        setEmail(status.userById?.data?.data?.email);
        setPhoneNumber(status.userById?.data?.data?.phone);
        setAadharCard(status.userById?.data?.data?.aadharcard);
        setpPinCode(status.userById?.data?.data?.pincode);
        setRationCard(status.userById?.data?.data?.ration_card?.String);
        setAddress(status.userById?.data?.data?.address);
        setCity(status.userById?.data?.data?.city);
        setCountry(status.userById?.data?.data?.country);
        setRole(status.userById?.data?.data?.role);
        setBlock(status.userById?.data?.data?.block);
        setdistrict(status.userById?.data?.data?.district);
        setPanchayat(status.userById?.data?.data?.panchayat);
        setstate(status.userById?.data?.data?.state);
      }
    }
  }, [status.userById?.data?.data]);
  // profile

  let param = window.location.href;
  const queryString = param.split("?")[1];
  if (queryString) {
    param = queryString.split("=")[1];
  } else {
    param = "";
  }
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      city: "",
      country: "",
      pincode: "",
      role: "",
      address: "",
      aadharcard: "",
      rationCard: "",
      block: "",
      district: "",
      panchayat: "",
      state: "",
    },
  });
  const onSubmit = () => {
    let apiData = {
      name: name ? name : userProfileData?.name,
      email: email ? email : userProfileData.email,
      phone: phone ? phone : userProfileData.phone,
      city: city ? city : userProfileData.city,
      country: country ? country : userProfileData.country,
      pincode: parseInt(pincode ? pincode : userProfileData.pincode),
      role: role ? role : userProfileData.role,
      address: address ? address : userProfileData.address,
      block: block ? block : userProfileData.block,
      panchayat: panchayat ? panchayat : userProfileData.panchayat,
      district: district  ? district : userProfileData.district,
      state: state ? state : userProfileData.state,
      profileImage: profileImage.name,
      aadharcard: parseInt(
        aadharcard ? aadharcard : userProfileData.aadharcard
      ),
      ration_card: rationCard
        ? rationCard
        : userProfileData?.ration_card?.String,
    };
    dispatch(editProfile(apiData, userId));
  };
  useEffect(() => {
    if (status.editProfile?.data?.response?.data?.statusCode === 400) {
      toast.error(status.editProfile?.data?.response?.data?.messageToUser, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    if (status.editProfile.data !== "") {
      if (status.editProfile.data.status === 200) {
        toast.success("Profile update successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      
        setTimeout(() => {
          let path = `/dashboard/AllUsers`;
          navigate(path);

          window.location.reload();
        }, 4000);
      }
    }
  }, [status]);
  // useEffect(() => {
  //   {
  //     errors.email &&
  //       errors.email.type === "required" &&
  //       toast.error("Please enter email address", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //   }
  //   {
  //     errors.email &&
  //       errors.email.type === "pattern" &&
  //       toast.error("Please enter valid email address", {
  //         position: "top-right",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
  //   }
  // }, [errors?.email]);
  return (
    <>
      <div className="topSection">
        <div className="topSectionleft">
          <h3>
            Settings
            <span>
              <img alt="" src="../../image/setting.svg"></img> / Settings
            </span>
          </h3>
        </div>
        <div className="topSectionright">
          <div className="mainbtn">
            <Link to="/dashboard/AddNewUser">Add New User</Link>
            <Link to="/dashboard/AddNewDevice">Add New Device</Link>
          </div>
        </div>
      </div>
      <div className="dashboardcontant">
        <div className="devicechart tabalwrap inputwrap">
          <div className="tablewrappadd">
            <div className="bothwrap">
              <div className="adduserhadd">
                <h2>Profile Settings</h2>
                <p>Update your profile</p>
              </div>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="adduserwrap">
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../../image/profile.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Name"
                    maxRows={4}
                    variant="filled"
                    name="name"
                    onChange={(e) => setName(e.target.value)}
                    InputLabelProps={{
                      shrink: name ? true : false,
                    }}
                    value={name}
                  />
                </div>
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../../image/mail.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Email"
                    maxRows={4}
                    variant="filled"
                    {...register("email", {
                      // required: "required",
                      pattern: /\S+@\S+\.\S+/,
                    })}
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    InputLabelProps={{
                      shrink: email ? true : false,
                    }}
                    value={email}
                  />
                </div>
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../../image/phone.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Mobile"
                    name="phone"
                    type="number"
                    maxRows={4}
                    variant="filled"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    InputLabelProps={{
                      shrink: phone ? true : false,
                    }}
                    value={parseInt(phone)}
                  />
                </div>
              </div>
            {/* block panchayat district state */}

              <div className="adduserwrap imageuploadmar">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../../image/upload.svg"></img>
                  </span>
                  <label htmlFor="upload-photo" className="chosefole">
                    <input
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="upload-photo"
                      type="file"
                      onChange={(e) => setProfileImage(e.target.files[0])}
                      defaultValue={userProfileData?.image}
                    />
                    <Button
                      color="secondary"
                      variant="contained"
                      component="span"
                      id="choose"
                    >
                      Upload Profile image
                    </Button>
                    <span>Supported Files JPG, PDF, PNG</span>
                  </label>
                </div>
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../../image/profile.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Aadhar Number"
                    maxRows={4}
                    type="number"
                    variant="filled"
                    name="aadharcard"
                    onChange={(e) => setAadharCard(e.target.value)}
                    InputLabelProps={{
                      shrink: aadharcard ? true : false,
                    }}
                    value={aadharcard}
                  />
                </div>
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../../image/profile.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Ration Card"
                    maxRows={4}
                    type="text"
                    variant="filled"
                    name="rationCard"
                    onChange={(e) => setRationCard(e.target.value)}
                    InputLabelProps={{
                      shrink: rationCard ? true : false,
                    }}
                    value={rationCard}
                  />
                </div>
              </div>
              <div className="adduserwrap">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Block"
                    multiline
                    maxRows={4}
                    variant="filled"
                    onChange={(e) => setBlock(e.target.value)}
                    InputLabelProps={{
                      shrink: block ? true : false,
                    }}
                    defaultValue={userProfileData?.block}
                  />
                </div>
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Panchayat"
                    multiline
                    maxRows={4}
                    variant="filled"
                    onChange={(e) => setPanchayat(e.target.value)}
                    InputLabelProps={{
                      shrink: panchayat ? true : false,
                    }}
                    defaultValue={userProfileData?.panchayat}
                  />
                </div>
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="District"
                    multiline
                    maxRows={4}
                    variant="filled"
                    onChange={(e) => setdistrict(e.target.value)}
                    InputLabelProps={{
                      shrink: district ? true : false,
                    }}
                    defaultValue={userProfileData?.district}
                  />
                </div>

                </div>

                <div className="adduserwrap">

                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="State"
                    multiline
                    maxRows={4}
                    variant="filled"
                    onChange={(e) => setstate(e.target.value)}
                    InputLabelProps={{
                      shrink: state ? true : false,
                    }}
                    defaultValue={userProfileData?.state}
                  />
                </div>
                <div className="customfield custom-number">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Pin Code"
                    maxRows={4}
                    type="number"
                    variant="filled"
                    onChange={(e) => setpPinCode(e.target.value)}
                    InputLabelProps={{
                      shrink: pincode ? true : false,
                    }}
                    value={pincode}
                  />
                </div>
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../../image/location.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Village"
                    multiline
                    maxRows={4}
                    variant="filled"
                    onChange={(e) => setCity(e.target.value)}
                    InputLabelProps={{
                      shrink: city ? true : false,
                    }}
                    defaultValue={userProfileData?.city}
                  />
                </div>
              </div>
              <div className="adduserwrap">
                <div className="customfield">
                  <span className="pageimage">
                    <img alt="img" src="../../image/flag.svg"></img>
                  </span>
                  <TextField
                    id="filled-multiline-flexible"
                    label="Country"
                    multiline
                    maxRows={4}
                    variant="filled"
                    onChange={(e) => setCountry(e.target.value)}
                    InputLabelProps={{
                      shrink: country ? true : false,
                    }}
                    defaultValue={userProfileData.country}
                  />
                </div>
                <div className="selectfield pro1">
                  <div className="customfield">
                    <span className="pageimage">
                      <img alt="img" src="../../image/users.svg"></img>
                    </span>
                    <Box component="form" noValidate autoComplete="off">
                      <div>
                        <TextField
                          id="filled-select-currency"
                          select
                          label="Select Role"
                          value={role}
                          variant="filled"
                          name="role"
                          onChange={(e) => setRole(e.target.value)}
                        >
                          {currencies.map((option) => (
                            <MenuItem value={option.value} key={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </TextField>
                      </div>
                    </Box>
                    <img
                      alt="img"
                      src="../../image/arrowb.svg"
                      className="arrowb"
                    ></img>
                  </div>
                </div>
              </div>
              <div className="mainbtn bulk">
                <button type="submit" className="save">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ProfileSettings;
